var SEARCHQUERYSTRING = ["q", "query", "p", "keywords"];

function ReferCookie() {
    this.createCookie = function (name, value, days) {
        var expires;
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        }
        else expires = "";
        document.cookie = name + "=" + value + expires + "; path=/";
    }

    this.readCookie = function (name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    this.eraseCookie = function (name) {
        createCookie(name, "", -1);
    }

    this.getParameterByName = function (url, name) {
        name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
        var regexS = "[\\?&]" + name + "=([^&#]*)";
        var regex = new RegExp(regexS, "i");
        var results = regex.exec(url);
        if (results == null)
            return null;
        else
            return decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    this.calculateSearchPhrase = function (url) {
        var len = SEARCHQUERYSTRING.length;
        for (var i = 0; i < len; i++) {
            var value = SEARCHQUERYSTRING[i];
            var searchquery = this.getParameterByName(url, value);
            if (searchquery) {
                return searchquery;
            }
        }
        return null;
    }

    this.getReferCookieValue = function () {
        var referrer = document.referrer;
        if (!referrer || referrer.length == 0) {
            return null;
        }
        var searchphrase = this.calculateSearchPhrase(referrer);
        var host = referrer.split('/')[2];
        if (this.getParameterByName(window.location.href, 'gclid')) {
            host = "google (cpc)";
        } else if (this.getParameterByName(window.location.href, 'ysmwa')) {
            host = "yahoo (cpc)";
        }
        if (searchphrase) {
            return "#" + host + "&" + searchphrase;
        }
        else {
            return "#" + host;
        }
    }

    this.saveReferCookie = function () {
        var refcook = this.readCookie("VISITREFER");
        if (!refcook) {
            // calculate refercookie
            var refercookievalue = this.getReferCookieValue();
            if (!refercookievalue)
                refercookievalue = "direct";
            this.createCookie("VISITREFER", refercookievalue, 90);
        }
    }
}

$(function () {
    $('select.statedropdown, .select-flag').change(function () {
        var selstate = $(this).val();
        if (selstate) {
            window.location.href = "/state/" + selstate;
        }
    });
    $('select.statedropdownfaq').change(function () {
        var selstate = $(this).val();
        if (selstate) {
            window.location.href = "/FAQ/" + selstate;
        }
    });
    $('select.statedropdownexamprep').change(function () {
        var selstate = $(this).val();
        if (selstate) {
            window.location.href = "/registerexamprep/?statecode=" + selstate;
        }
    });
    $('.sf-menu').superfish();
    var cook = new ReferCookie();
    cook.saveReferCookie();
});

//js function for modal popup 
    $(function () {
        $('.bubbleInfo2').each(function () {
            var distance = 0;
            var time = 250;
            var hideDelay = 5000;

            var hideDelayTimer = null;

            var beingShown = false;
            var shown = false;
            var trigger = $('.trigger2', this);
            var info = $('.popup2', this).css('opacity', 0);


            $([trigger.get(0), info.get(0)]).mouseover(function () {
                if (hideDelayTimer) clearTimeout(hideDelayTimer);
                if (beingShown || shown) {
                    // don't trigger the animation again
                    return;
                } else {
                    // reset position of info box
                    beingShown = true;

                    info.css({
                        top:-5,
                        left:-10,
                        display: 'block'
                    }).animate({
                        top: '-=' + distance + 'px',
                        opacity: 1
                    }, time, 'swing', function() {
                        beingShown = false;
                        shown = true;
                    });
                }

                return false;
            }).mouseout(function () {
                if (hideDelayTimer) clearTimeout(hideDelayTimer);
                hideDelayTimer = setTimeout(function () {
                    hideDelayTimer = null;
                    info.animate({
                        top: '-=' + distance + 'px',
                        opacity: 0
                    }, time, 'swing', function () {
                        shown = false;
                        info.css('display', 'none');
                    });

                }, hideDelay);

                return false;
            });
        });
    });
    
	
	
//parents center select state

//js function for modal popup 
    $(function () {
        $('.bubbleInfo3').each(function () {
            var distance = 0;
            var time = 250;
            var hideDelay = 5000;

            var hideDelayTimer = null;

            var beingShown = false;
            var shown = false;
            var trigger = $('.trigger2', this);
            var info = $('.popup2', this).css('opacity', 0);


            $([trigger.get(0), info.get(0)]).mouseover(function () {
                if (hideDelayTimer) clearTimeout(hideDelayTimer);
                if (beingShown || shown) {
                    // don't trigger the animation again
                    return;
                } else {
                    // reset position of info box
                    beingShown = true;

                    info.css({
                        top:-5,
                        left:-10,
                        display: 'block'
                    }).animate({
                        top: '-=' + distance + 'px',
                        opacity: 1
                    }, time, 'swing', function() {
                        beingShown = false;
                        shown = true;
                    });
                }

                return false;
            }).mouseout(function () {
                if (hideDelayTimer) clearTimeout(hideDelayTimer);
                hideDelayTimer = setTimeout(function () {
                    hideDelayTimer = null;
                    info.animate({
                        top: '-=' + distance + 'px',
                        opacity: 0
                    }, time, 'swing', function () {
                        shown = false;
                        info.css('display', 'none');
                    });

                }, hideDelay);

                return false;
            });
        });
    });
    // Chat click trigger
    $(document).ready(function(){
        $('.chat-button').click(function () {
            if (typeof $zopim != 'undefined') {
                $zopim.livechat.setLanguage('en');
                $zopim.livechat.window.show();
            }
        });
    });