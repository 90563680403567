/// <reference path="../../node_modules/@types/jquery/index.d.ts" /> 

declare const $zopim: any;

$(document).ready(() => {

    let zopimLoaded = false;

    const $chatButton = $('.chat-button');
    $chatButton.on('click', () => {
        if (!zopimLoaded) {
            const el = document.createElement('script');
            el.src = '/dist/js/snapengage.js';
            el.async = false;
            document.body.appendChild(el);

            el.addEventListener('load', () => {
                const interval = setInterval(() => {
                    if ($zopim && $zopim.livechat) {
                        $zopim.livechat.setLanguage('en');
                        $zopim.livechat.window.show();
                        clearInterval(interval);
                        zopimLoaded = true;
                    }
                }, 1000)
            });
        } else {
            $zopim.livechat.window.show();
        }
    });
});