;(function($, window, document) {
	'use strict';
	
	var Evilpopups = {
		
		init: function(config) {
			this.config = config;
			return this;
		},
		
		bindEvents: function() {
			if (this.checkCookies()) this.detectLocation();
			
			this.config.buttons.on('click', this.removePopup);
			this.config.facebookBtn.on('mouseover', this.removePopup);
			this.config.discountOffer.on('hide', this.removePopup);	
		},
		
		removePopup: function() {
			window.onbeforeunload = function() {};
		},
		
		detectLocation: function() {
			if (window.location.href.search('register2') !== -1) {
				window.onbeforeunload = this.discountOfferPopup;
			}	
		},
		
		discountOfferPopup: function(e) {
			$.cookie('discount', 'offered');
			Evilpopups.config.discountOffer.modal();
			
  			return 'Return to the registration form and enter the code for a discount';
		},
		
		checkCookies: function() {
			return $.cookie('discount') !== 'offered';	
		}
	};
	
	$(function() {
			Evilpopups.init({
				discountOffer	: $('#discount-offer'),
				facebookBtn		: $('.oauth'),
				buttons			: $('.btn, .reg-later-link, #ctl00_MainContent_hlChangePassword, #ctl00_ctl00_MainContent_MainContent_hlSpanish')	
		}).bindEvents();	
	});
	
})(jQuery, window, document);